import React, { useState } from "react";
import axios from "axios";

const ChatAi = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [input, setInput] = useState("");
	const [response, setResponse] = useState("");
	const [question, setQuestion] = useState("");
	const [loading, setLoading] = useState(false);

	const toggleChat = () => {
		setIsOpen(!isOpen);
	};

	const sendMessage = async () => {
		setResponse("");
		setInput("");
		setQuestion(input);
		try {
			setLoading(true);
			const apiUrl = "https://api.openai.com/v1/chat/completions"; // Update with the correct API endpoint
			const apiKey =
				"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"; // Replace with your actual API key
			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${apiKey}`,
			};

			const requestBody = {
				model: "gpt-4o-mini",
				response_format: { type: "text" },
				messages: [
					{
						role: "user",
						content: `${input}`,
					},
				],
			};

			const { data } = await axios.post(apiUrl, requestBody, { headers });

			setResponse(data.choices[0].message.content);
		} catch (error) {
			console.error("Error sending message:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<button
				className="fixed bottom-4 right-10 bg-white border p-1 rounded-full shadow-lg"
				onClick={toggleChat}
			>
				<img
					className="mx-auto rounded-full"
					width={80}
					src={`${window.location.origin}/assistant_amhac.png`}
					alt="assistant_amhac"
				/>
			</button>

			{isOpen && (
				<div className="fixed bottom-6 right-36 bg-white border border-gray-300 rounded-lg shadow-lg w-96 h-1/2 flex flex-col">
					<div className="p-4 flex-grow">
						<h2 className="text-lg font-semibold">Assistant AMHAC</h2>
						<div className="mt-2 text-sm italic">
							{question ? (
								question
							) : (
								<span>
									Bonjour !<br />
									Comment puis-je vous aider ?<br />
									Une question sur un DTU, sur la pose d'un matériau spécifique
									ou un procédé particulier ?
								</span>
							)}
						</div>
					</div>
					{response && (
						<div className="w-full p-4 overflow-auto border-t">
							<span className="font-medium">Ma réponse :</span>
							<div>{response}</div>
						</div>
					)}
					<div className="w-full p-2 flex border-t">
						<div className="w-full">
							<textarea
								className="w-full border border-gray-400"
								type="text"
								rows={2}
								placeholder=""
								value={input}
								onChange={(e) => setInput(e.target.value)}
							/>
						</div>
						<div className="p-2">
							<button
								className="bg-blue-300 text-white rounded-full p-2 hover:bg-blue-950 hover:text-white h-full"
								onClick={input && sendMessage}
							>
								{loading ? (
									<div
										className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
										role="status"
									>
										<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
											Loading...
										</span>
									</div>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-10"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
										/>
									</svg>
								)}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChatAi;
