import moment from "moment";
import React, { useEffect, useState } from "react";
import { PlanificationsService } from "~/services/planificationsService";

const InfosPlanification = (props) => {
	const planificationId = props.planificationId ? props.planificationId : 0;
	const [iconStatut, setIconStatut] = useState();
	const [statut, setStatut] = useState();
	const [majStatut, setMajStatut] = useState();
	const setResponse = props.setResponse;

	// Création du service API et appel du hook utilisé pour la création de planifications
	const planificationsService = new PlanificationsService();
	const {
		data: apiGetDataPlanification,
		error: apiGetErrorPlanification,
		isLoading: apiGetIsLoadingPlanification,
		callToAction: apiGetHandlerPlanification,
	} = planificationsService.useGetOne(planificationId);

	const {
		data: apiUpdatePlanification,
		error: apiUpdateErrorPlanification,
		isLoading: apiUpdateIsLoadingPlanification,
		callToAction: apiUpdateHandlerPlanification,
	} = planificationsService.useUpdate(planificationId, majStatut);

	const arrayIcons = [
		"💬",
		"☎",
		"🔒",
		"☑",
		"⚠",
		"⚒",
		"👍",
		"👎",
		"⭐",
		"🔍",
		"📌",
		"📄",
		"📅",
	];
  
	useEffect(() => {
		setResponse(apiUpdatePlanification);
	}, [apiUpdatePlanification]);

	useEffect(() => {
		if (majStatut) {
			apiUpdateHandlerPlanification();
		}
	}, [majStatut]);

	useEffect(() => {
		if (planificationId > 0) {
			setIconStatut();
			setStatut();
			if (apiGetDataPlanification) {
				setIconStatut(
					apiGetDataPlanification.statut &&
						apiGetDataPlanification.statut.split("~")[0],
				);
				setStatut(
					apiGetDataPlanification.statut &&
						apiGetDataPlanification.statut.split("~")[1],
				);
			}
		}
	}, [apiGetDataPlanification]);

	return (
		<>
			{/* ☀ ☂ ☁ ❄ */}
			<div className="h-fit my-2 mx-auto basis-1/3 border-2 rounded-md p-2 shadow-md">
				<h1 className="text-xl m-2">
					{apiGetDataPlanification &&
						moment(apiGetDataPlanification.date).format("DD/MM/YYYY") +
							" - " +
							apiGetDataPlanification.assigned_user.first_name}
				</h1>
				<div className="">
					<span className="">Ajouter un statut à cette date : </span>
					{arrayIcons.map((icon) => {
						return (
							<button
								className="hover:-translate-y-1 w-5"
								onClick={() => setIconStatut(icon)}
							>
								{icon}
							</button>
						);
					})}
				</div>
				<div>
					{iconStatut && (
						<div className="w-full flex">
							<span className="text-2xl">{iconStatut}</span>
							<input
								className="w-full"
								type="text"
								name="statut"
								value={statut}
								onChange={(e) => setStatut(e.target.value)}
							/>
							<button
								onClick={() =>
									setMajStatut({
										statut: iconStatut + "~" + statut,
									})
								}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
									/>
								</svg>
							</button>
							<button
								onClick={() => {
									setMajStatut({
										statut: "",
									});
									setIconStatut();
									setStatut();
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
									/>
								</svg>
							</button>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default InfosPlanification;
