import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Chat from "~/components/chatGpt";

export default function Index() {
	const [pageTitle, setPageTitle] = useOutletContext();
	const [estimation, setEstimation] = useState("");

	const createTable = (data) => {
		return (
			<table className="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-lg">
				<thead className="bg-gray-50 text-center">
					<tr>
						<th
							scope="col"
							className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Description de l'ouvrage
						</th>
						<th
							scope="col"
							className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Quantité
						</th>
						<th
							scope="col"
							className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Unité
						</th>
						<th
							scope="col"
							className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Prix unitaire HT
						</th>
						<th
							scope="col"
							className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Prix total HT
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{JSON.parse(data).chantier?.map((item, i) => (
						<tr key={i}>
							<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
								{item["Description de l'ouvrage"]}
							</td>
							<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
								{item["quantité"]}
							</td>
							<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
								{item["unité"]}
							</td>
							<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
								{item["prix unitaire HT"]}
							</td>
							<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
								{item["prix total HT"]}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	const totalHt = (data) => {
		let total = 0;
		JSON.parse(data).chantier?.map((item) => {
			total += item["prix total HT"];
		});
		return total;
	};

	useEffect(() => {
		setPageTitle("Votre Assistant AMHAC");
	}, []);

	return (
		<div className="w-full flex flex-col justify-center content-center">
			<div className="flex mx-auto">
				<div className="w-1/2">
					<img
						className="mx-auto"
						width={400}
						src="./assistant_amhac.png"
						alt="assistant_amhac"
					/>
				</div>
				<div className="w-1/2 flex-row">
					<div className="">
						<p>
							Bonjour, je suis votre assistant personnalisé développé par
							l'équipe de AMHAC.
						</p>
						<p>
							Je suis capable de répondre à vos questions et de vous fournir des
							estimations sur des chantiers à chiffrer afin de vous faire gagner
							du temps.
						</p>
						<p>
							Pour cela essayez de me détailler le travail à réaliser chez votre
							client, en prenant soin de me donner suffisament de détails comme
							les m2, les revêtements ou les contraintes.
						</p>
					</div>
					<div className="my-5">
						<p>Que dois-je vous estimer ?</p>
						<Chat estimation={setEstimation} />
					</div>
				</div>
			</div>
			{estimation && (
				<div className="w-3/4 mx-auto my-5">
					<div className="text-center">
						<h1 className="text-xl font-medium">Voici votre estimation</h1>
						{totalHt && `Le montant total est de ${totalHt(estimation)} HT`}
					</div>
					<div className="text-center my-5">{createTable(estimation)}</div>
                    <div className="text-center text-sm my-5">Attention ceci est une estimation et ne peut être en aucun cas considéré comme un devis en l'état.</div>
				</div>
			)}
		</div>
	);
}
