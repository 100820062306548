import { useEffect } from "react"
import { AuthService } from "~/services/authService"
import { useNavigate } from "~/router"
import { HashLoader } from "react-spinners"

export default function Logout() {

    /* hook utile à la redirection */
    const navigate = useNavigate()

    /* Le service API d'authentification et chargement du hook de logout */
    const authService = new AuthService()
    const {data: apiLogoutData, error: apiLogoutError, isLoading: apiLogoutIsLoading, callToAction: apiLogoutHandler} = authService.useLogout()
    
    /* Au chargement, on lance la procédure de logout */
    useEffect(() => {
        apiLogoutHandler()
    }, [])

    /* Lorsque le status de l'utilisateur change on redirige vers le login */
    useEffect(() => {
        if(!apiLogoutData.isLoggedIn)
            navigate('/login')
    }, [apiLogoutData.isLoggedIn])

    return (
        <div className="sm:py-8 h-screen">
            <div className="sm:mx-auto sm:w-full sm:max-w-lg sm:rounded-xl sm:border sm:border-gray-500 bg-white shadow-xl p-2 flex flex-col">
                <div>
                    <HashLoader size={20} loading={apiLogoutIsLoading} className="mx-auto"/>
                    <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-9000">Déconnection</h2>
                </div>
                <div className="mt-10 text-center font-md">
                    <p>La déconnexion est en cours, vous allez être redirigé vers la page d'authentification</p>
                    <p className="text-red-600">{apiLogoutError && apiLogoutError.message}</p>
                    <p className="text-red-600"></p>
                </div>
            </div>
        </div>
    )
}