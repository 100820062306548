import { Link, useOutletContext } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "~/router";
import { useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  ArrowDownOnSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ClientsService } from "~/services/clientsService";
import { types_clients } from "~/utils/refs.js";
import InputSearchBAN from "~/components/InputSearchBAN";

export default function AddClient() {
  // Permets la redirection apres la création
  const navigate = useNavigate();

  // State servant au stockage des données de la personnes à créer
  const [stateData, setStateData] = useState({
    name: "",
    alt_name: "",
    address: "",
    city: "",
    postal_code: "",
    phone1: "",
    phone2: "",
    type: "",
    active: true,
  });

  // Création du service API et appel du hook utilisé pour la création de clients
  const clientsService = new ClientsService();
  const {
    data: apiCreateData,
    error: apiCreateError,
    isLoading: apiCreateIsLoading,
    callToAction: apiCreateHandler,
  } = clientsService.useCreate(stateData);

  // Permet de définir le titre de la page
  const [_, setPageTitle] = useOutletContext();

  // Au chargement, on change le titre de la page
  useEffect(() => {
    setPageTitle("Ajout d'un client");
  });

  useEffect(() => {
    if (apiCreateData) navigate("/clients");
  }, [apiCreateData]);

  // En cas d'erreur et code 401 (not authenticated) : on redirige vers la page de login
  if (apiCreateError) {
    if (apiCreateError.response && apiCreateError.response.status === 401)
      return <Navigate to="/logout" />;
  }

  return (
    <>
      {/* Header affichant le bouton retour, le message d'erreur si il en a un, et le bouton sauvegarder */}
      <div className="w-full md:w-2/3 flex justify-between text-center mx-auto">
        <Link
          className="rounded-md border border-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-500 p-4"
          to={`/clients/`}
          title="Retour a la liste"
        >
          <ArrowLeftIcon className="h-4 inline" />
        </Link>

        {apiCreateError && (
          <span className="text-red-600">{apiCreateError.message}</span>
        )}

        <button
          className={`rounded-md border border-green-600 ${
            apiCreateIsLoading
              ? "bg-green-200"
              : "hover:bg-green-600 hover:text-white"
          } transition-colors duration-500 p-4`}
          onClick={apiCreateHandler}
          disabled={apiCreateIsLoading}
        >
          <ArrowDownOnSquareIcon className="h-4 inline" />
        </button>
      </div>
      {/* La table affichant le formulaire */}
      <table className="w-full md:w-2/3 text-lg mt-4 table-auto mx-auto">
        <tbody>
          <tr>
            <td className="font-bold py-4">Type</td>
            <td>
              <select
                data-te-select-initid="type"
                name="type"
                type="text"
                className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                //placeholder="xxx"
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    type: e.target.value != "" ? e.target.value : null,
                  })
                }
              >
                <option value=""></option>
                {types_clients.map((type) => {
                  return <option value={type}>{type}</option>;
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Nom du client</td>
            <td>
              <input
                id="name"
                name="name"
                className="rounded-lg w-full"
                type="text"
                value={stateData.name}
                onChange={(e) =>
                  setStateData({ ...stateData, name: e.target.value })
                }
              />
            </td>
          </tr>
          <InputSearchBAN data={stateData} maj={setStateData} />
          <tr>
            <td className="font-bold py-4">Téléphone 1</td>
            <td>
              <input
                id="phone1"
                name="phone1"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone1}
                onChange={(e) =>
                  setStateData({ ...stateData, phone1: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Téléphone 2</td>
            <td>
              <input
                id="phone2"
                name="phone2"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone2}
                onChange={(e) =>
                  setStateData({ ...stateData, phone2: e.target.value })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
