import { useState, useEffect } from "react"
import { AuthService } from "~/services/authService"
import { useNavigate } from "~/router"
import { HashLoader } from "react-spinners"

export default function Login() {

    /* Ce state permet de stocker les valeurs du formulaire */
    const [formState, setFormState] = useState({
        username: '',
        password: ''
    })
    
    /* Ce state permet d'afficher un message d'erreur personnalisé */
    const [errorMessage, setErrorMessage] = useState('')

    /* Le service API pour l'authentification et on charge le hook de login */
    const authService = new AuthService()
    const { data: apiLoginData, error: apiLoginError, isLoading: apiLoginIsLoading, callToAction: apiLoginHandler } = authService.useLogin(formState.username, formState.password)
    
    /* Ce hook permet la redirection */
    const navigate = useNavigate()

    /* On change le titre de la page */
    useEffect(() => {
        document.title = "Authentification"
    }, [])

    /* Lorsque le hook nous renvois un utilisateur loggé, on redirige vers l'accueil */
    useEffect(() => {
        if(apiLoginData.isLoggedIn)
            navigate('/')
    }, [apiLoginData.isLoggedIn])
    
    /* En cas d'erreur, on la déchiffre et on l'affiche */
    useEffect(() => {
        if(apiLoginError) {
            console.log(apiLoginError)
            if(apiLoginError.response && apiLoginError.response.status === 401) setErrorMessage("Erreur d'identifiant ou de mot de passe")
            else setErrorMessage("Erreur inconnue")
        } else {
            setErrorMessage(null)
        }
    }, [apiLoginError])

    return ( 
        <div className="sm:py-8 h-screen">
            <div className="sm:mx-auto sm:w-full sm:max-w-lg sm:rounded-xl sm:border sm:border-gray-500 bg-white shadow-xl p-2 flex flex-col">
                <div>
                    <img className="mx-auto h-12 w-12 rounded-full" 
                                    src="amac.png" alt="Amhac" />
                    <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-9000">Authentification</h2>
                </div>
                <div className="mt-10">
                    <form className="space-y-6" onSubmit={(e) => {e.preventDefault(); apiLoginHandler()}}>
                        <div>
                            <label htmlFor="username" className="block text-md font-medium leading-gray-900">Utilisateur</label>
                            <div className="mt-2">
                                <input type="text" id="username" name="username" required autoFocus
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={formState.username} onChange={e => setFormState({...formState, username:e.target.value})}/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-md font-medium leading-gray-900">Mot de passe</label>
                            <div className="mt-2">
                                <input type="password" id="password" name="password" required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={formState.password} onChange={e => setFormState({...formState, password:e.target.value})}/>
                            </div>
                        </div>
                        <div>
                            <button type="submit" disabled={apiLoginIsLoading} onClick={apiLoginHandler}
                            className={`inline flex w-full justify-center rounded-md ${apiLoginIsLoading ? "bg-indigo-200" : "bg-indigo-600"} px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ${!apiLoginIsLoading && "hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}`}
                            >{apiLoginIsLoading ? <span className="inline"><HashLoader size={20} loading={apiLoginIsLoading} cssOverride={{display: 'inline', marginRight:'18px'}} color="white"/>Connexion en cours</span> : "Se connecter"}</button>
                        </div>
                    </form>
                    {errorMessage && <span className="block rounded-xl text-center font-bold my-4 mx-4 border border-red-500 text-red-800">{errorMessage}</span>}
                </div>
            </div>
        </div>
    )

}