import moment from "moment";
import React, { useState } from "react";
import CardPlanif from "~/components/planning/CardPlanif";
import { controlDayOff } from "~/utils/lib";
import InfosChantier from "~/components/planning/InfosChantier";
import InfosPlanification from "~/components/planning/InfosPlanification";
import InfosStats from "~/components/planning/InfosStats";

const QuarterView = (props) => {
  const persons = props.persons ? props.persons : [];
  const currentDate = props.currentDate ? props.currentDate : new Date();
  const editPlanning = props.editPlanning ? props.editPlanning : false;
  const deletePlanning = props.deletePlanning ? props.deletePlanning : false;
  const selectedChantier = props.selectedChantier;
  const setStateData = props.setStateData;
  const planifications = props.planifications ? props.planifications : [];
  const setDeleteId = props.setDeleteId;
  const setUpdateId = props.setUpdateId;
  const updateId = props.updateId;
  const showWeekend = props.showWeekend ? props.showWeekend : false;
  const hoverId = props.hoverId ? props.hoverId : "";
  const setHoverId = props.setHoverId ? props.setHoverId : "";
  const [personId, setPersonId] = useState();
  const setResponse = props.setResponse;

  const listPersons = () => {
    return persons
      .sort((a, b) => a.subcontractor - b.subcontractor)
      .map((person, i) => {
        return (
          <>
            <tr className="text-center h-14 border">
              <td className="font-bold">
                <div className="flex">
                  <div className="m-auto flex">
                    {person.subcontractor ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-4 h-4 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-4 h-4 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                        />
                      </svg>
                    )}{" "}
                    {person.first_name}
                  </div>
                </div>
              </td>

              {arrayDays().map((day) => {
                return (
                  <td
                    className={`${
                      controlDayOff(day) && "bg-slate-400"
                    } border align-bottom`}
                  >
                    {planifications?.map((planification) => {
                      if (
                        planification.assigned_user_id == person.id &&
                        planification.date == day
                      )
                        return (
                          <CardPlanif
                            planification={planification}
                            deletePlanning={deletePlanning}
                            editPlanning={editPlanning}
                            setDeleteId={setDeleteId}
                            setUpdateId={setUpdateId}
                            updateId={updateId}
                            minimize={true}
                            hoverId={hoverId}
                            setHoverId={setHoverId}
                            personId={personId}
                            setPersonId={setPersonId}
                          />
                        );
                    })}
                    {selectedChantier && (
                      <button
                        onClick={() =>
                          setStateData({
                            date: day,
                            chantier_id: selectedChantier,
                            assigned_user_id: person.id,
                          })
                        }
                        className="w-full bg-slate-200 text-slate-400 hover:shadow-md"
                      >
                        +
                      </button>
                    )}
                  </td>
                );
              })}
            </tr>

            {personId == person.id && (
              <tr className="bg-white">
                <td colSpan={1000}>
                  <div className="w-full px-2">
                    <div className="flex gap-2">
                      <InfosChantier
                        hoverId={hoverId}
                        setResponse={setResponse}
                      />
                      <InfosPlanification
                        planificationId={updateId}
                        setResponse={setResponse}
                      />
                      <InfosStats
                        hoverId={hoverId}
                        setResponse={setResponse}
                        persons={persons}
                        planifications={planifications}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </>
        );
      });
  };

  const arrayDays = () => {
    let startMonth = moment(currentDate).clone().startOf("month");
    let endMonth = moment(currentDate).clone().add(2, "months").endOf("month");
    let arrayDays = [];

    for (let index = 0; index < endMonth.diff(startMonth, "days"); index++) {
      let date = moment(startMonth).add(index, "day").format("dddd");
      if (showWeekend) {
        let date = moment(startMonth).add(index, "day");
        arrayDays = [...arrayDays, date.format("YYYY-MM-DD")];
      } else {
        if (date != "Saturday" && date != "Sunday") {
          let date = moment(startMonth).add(index, "day");
          arrayDays = [...arrayDays, date.format("YYYY-MM-DD")];
        }
      }
    }
    return arrayDays;
  };

  const listDays = () => {
    if (arrayDays().length > 0) {
      return arrayDays().map((day) => {
        return (
          <th
            className={`${controlDayOff(day) && "bg-slate-400"} border text-sm`}
          >
            ({moment(day).week()})<br />
            {moment(day).format("D")}
          </th>
        );
      });
    }
  };

  return (
    <table className="w-full border text-sm">
      <thead className="border">
        <tr className="h-14">
          <th>Intervenants</th>
          {listDays()}
        </tr>
      </thead>
      <tbody className="border">{listPersons()}</tbody>
    </table>
  );
};

export default QuarterView;
