import { useState } from "react";
import { protectedAPI } from "./base";
import useSWR from "swr";

export class RolesService {
  useGetAll() {
    const fetcher = (url) => protectedAPI.get(url).then((res) => res.data);

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      "/admin/roles",
      fetcher
    );

    return {
      data: data,
      error,
      isLoading: isLoading || isValidating,
      callToAction: mutate,
    };
  }
}
