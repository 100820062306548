import React, { useState } from "react";
import axios from "axios";

const Chat = (props) => {
	const [input, setInput] = useState("");
	const [response, setResponse] = useState("");
	const [loading, setLoading] = useState(false);

	const sendMessage = async () => {
		try {
			setLoading(true);
			const apiUrl = "https://api.openai.com/v1/chat/completions"; // Update with the correct API endpoint
			const apiKey =
				"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"; // Replace with your actual API key
			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${apiKey}`,
			};

			const requestBody = {
				model: "gpt-4o-mini",
				response_format: { type: "json_object" },
				messages: [
					{
						role: "user",
						content: `${input}. Estimation au format JSON avec uniquement la clé chantier puis les colonnes suivantes : Description de l'ouvrage, quantité, unité, prix unitaire HT, prix total HT`,
					},
				],
			};

			const { data } = await axios.post(apiUrl, requestBody, { headers });

			setResponse(data.choices[0].message.content);
		} catch (error) {
			console.error("Error sending message:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<div>
				<textarea
					className="w-full"
					type="text"
					value={input}
					rows={6}
					placeholder="Exemple : Estime moi un chantier de rénovation d'une salle de bain de 10m2 avec hauteur sous plafond de 2m50 où une baignoire doit être remplacée par une douche. Le sol remplacé en carrelage et de la faïence sur tous les murs."
					onChange={(e) => setInput(e.target.value)}
				/>
				<button
					className="bg-blue-300 rounded-md p-2 hover:bg-blue-950 hover:text-white"
					onClick={input && sendMessage}
				>
					{!loading ? "C'est parti !" : "Estimation en cours..."}
				</button>
			</div>
			<div>
				<p>{props.estimation(response)}</p>
			</div>
		</div>
	);
};

export default Chat;
