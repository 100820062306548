
export function isLoggedIn() {
    return window.localStorage.getItem('access_token') ? true : false
}

export function getAccessToken() {
    return window.localStorage.getItem('access_token') ?? null
}

export function getRefreshToken() {
    return window.localStorage.getItem('refresh_token') ?? null
}

export function clearTokens() {
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('refresh_token')
    return true
}

export function saveTokens(access_token, refresh_token) {

    if (!access_token) return false
    if (!refresh_token) return false

    window.localStorage.setItem('access_token', access_token)
    window.localStorage.setItem('refresh_token', refresh_token)

    return true
}