import joursFeries from "@socialgouv/jours-feries";
import moment from "moment";

export const controlDayOff = (date) => {
  let array = Object.values(joursFeries(moment(date).format("YYYY")));
  if(array.length > 0) {
    let index = array.findIndex((element) => moment(element).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD'))
    if(index != -1) return true
  }
};

export const hexToRgb = (color) => {
  let hex = color.replace("#", "");
  let c_r = parseInt(hex.substring(0, 0 + 2), 16);
  let c_g = parseInt(hex.substring(2, 2 + 2), 16);
  let c_b = parseInt(hex.substring(4, 4 + 2), 16);
  let brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

  if (brightness > 50) {
    return true;
  } else {
    return false;
  }
};
