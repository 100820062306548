import axios from "axios";
import { clearTokens, getAccessToken,  getRefreshToken, saveTokens } from "../utils/auth";

let baseURL;

if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  // dev code
  baseURL = "http://localhost:8080"
} else {
  // production code
  baseURL = window.location.protocol+"//"+window.location.host+"/api"
}

export const publicAPI = axios.create({
    baseURL: baseURL,
    timeout: 30000,
})

export const protectedAPI = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
        "Authorization": `Bearer ${getAccessToken()}`
    }
})

protectedAPI.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers.Authorization = `Bearer ${getAccessToken()}`
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

protectedAPI.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      if (error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
  
          try {

            const response = await publicAPI.get('/oauth2/refresh', {
                headers: {
                    'Authorization' : `Bearer ${getRefreshToken()}`
                }
            })

            saveTokens(response.data.access_token, response.data.refresh_token)
            
            originalConfig.headers.Authorization = `Bearer ${response.data.access_token}`

          } catch(error) {

            clearTokens()
            return Promise.reject(error)

          }

          return protectedAPI(originalConfig);
        }
  
      }
  
      return Promise.reject(error);
    }
  );