import { Link, Navigate } from "~/router";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";
import DayView from "~/components/planning/views/DayView";
import WeekView from "~/components/planning/views/WeekView";
import MonthView from "~/components/planning/views/MonthView";
import moment from "moment";
import { UserService } from "~/services/userService";
import { ChantiersService } from "~/services/chantiersService";
import Chantiers from "~/components/planning/Chantiers";
import { PlanificationsService } from "~/services/planificationsService";
import QuarterView from "~/components/planning/views/QuarterView";
// import InfosChantier from "~/components/planning/InfosChantier";
// import InfosPlanification from "~/components/planning/InfosPlanification";

export default function Index() {
  /* Le state permettant de gérer la recherche */
  const [viewPlanning, setViewPlanning] = useState("week");
  const [dateView, setDateview] = useState(new Date());
  const [showChantiers, setShowChantiers] = useState(false);
  const [selectedChantier, setSelectedChantier] = useState();
  const [editPlanning, setEditPlanning] = useState(false);
  const [deletePlanning, setDeletePlanning] = useState(false);
  const [stateData, setStateData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [updateId, setUpdateId] = useState();
  const [showWeekend, setShowWeekend] = useState(false);
  const [hoverId, setHoverId] = useState();
  const [companionsView, setCompanionsView] = useState(true);
  const [subcontractorsView, setSubcontractorsView] = useState(true);
  const [response, setResponse] = useState({});

  // Création du service API et appel du hook utilisé pour la création de planifications
  const planificationsService = new PlanificationsService();
  const {
    data: apiCreateData,
    error: apiCreateError,
    isLoading: apiCreateIsLoading,
    callToAction: apiCreateHandler,
  } = planificationsService.useCreate(stateData);

  const {
    data: apiUpdateData,
    error: apiUpdateError,
    isLoading: apiUpdateIsLoading,
    callToAction: apiUpdateHandler,
  } = planificationsService.useUpdate(updateId);

  const {
    data: apiDeleteData,
    error: apiDeleteError,
    isLoading: apiDeleteIsLoading,
    callToAction: apiDeleteHandler,
  } = planificationsService.useDelete(deleteId);

  const {
    data: apiGetData,
    error: apiGetError,
    isLoading: apiGetIsLoading,
    callToAction: apiGetHandler,
  } = planificationsService.useGetAll();

  const chantiersService = new ChantiersService();
  const {
    data: apiGetDataChantiers,
    error: apiGetErrorChantiers,
    isLoading: apiGetIsLoadingChantiers,
    callToAction: apiGetHandlerChantiers,
  } = chantiersService.useGetAll();

  /* le service d'API et le hook permettant de récupérer toutes les unités DMO */
  const userService = new UserService();
  const {
    data: apiGetDataUsers,
    error: apiGetErrorUsers,
    isLoading: apiGetIsLoadingUsers,
    callToAction: apiGetHandlerUsers,
  } = userService.useGetAll();

  /* Le hook permettant de changer le titre de la page et le useEffect qui le fait */
  const [_, setPageTitle] = useOutletContext();

  useEffect(() => {
    setPageTitle("Planning");
  }, []);

  useEffect(() => {
    if (hoverId) {
      setShowChantiers(false);
    }
  }, [hoverId]);

  useEffect(() => {
    apiGetHandler();
  }, [response]);

  useEffect(() => {
    if (showChantiers) {
      setHoverId();
      setUpdateId();
    }
  }, [showChantiers]);

  useEffect(() => {
    if (stateData) {
      apiCreateHandler();
    }
  }, [stateData]);

  useEffect(() => {
    if (deleteId) {
      apiDeleteHandler();
    }
  }, [deleteId]);

  /* Si erreur 401 not authenticated, on redirige vers la page de logout */
  if (apiGetErrorChantiers) {
    if (
      apiGetErrorChantiers.response &&
      apiGetErrorChantiers.response.status === 401
    )
      return <Navigate to="/logout" />;
  }

  const filterUsers = () => {
    let filteredUsers = [];
    if (apiGetDataUsers) {
      apiGetDataUsers.map((user) => {
        if (companionsView && subcontractorsView) {
          if (user.on_planning) {
            filteredUsers = [...filteredUsers, user];
          }
        } else if (companionsView && !subcontractorsView) {
          if (user.on_planning && !user.subcontractor) {
            filteredUsers = [...filteredUsers, user];
          }
        } else if (!companionsView && subcontractorsView) {
          if (user.on_planning && user.subcontractor) {
            filteredUsers = [...filteredUsers, user];
          }
        }
      });
      return filteredUsers;
    }
  };

  useEffect(() => {
    apiGetHandler();
  }, [apiCreateData, apiDeleteData]);

  const navDate = () => {
    let showDate = "";
    if (viewPlanning == "day") {
      showDate = moment(dateView).format("dddd Do MMMM YYYY");
    }
    if (viewPlanning == "week") {
      showDate = "Semaine " + moment(dateView).week();
    }
    if (viewPlanning == "month") {
      showDate = moment(dateView).format("MMMM YYYY");
    }
    return (
      <div className="flex">
        <a
          onClick={() => {
            setDateview(new Date());
          }}
          href="#"
        >
          [Aujourd'hui]
        </a>
        <a
          onClick={() => {
            setDateview(moment(dateView).subtract(1, viewPlanning));
          }}
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mx-2 hover:-translate-x-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061A1.125 1.125 0 0 1 21 8.689v8.122ZM11.25 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061a1.125 1.125 0 0 1 1.683.977v8.122Z"
            />
          </svg>
        </a>
        {viewPlanning == "day" && showDate}
        {viewPlanning == "week" && showDate}
        {viewPlanning == "month" && showDate}
        {viewPlanning == "quarter" && showDate}
        <a
          onClick={() => {
            setDateview(moment(dateView).add(1, viewPlanning));
          }}
          href="#"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6 mx-2 hover:translate-x-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
            />
          </svg>
        </a>
        {(viewPlanning == "week" ||
          viewPlanning == "month" ||
          viewPlanning == "quarter") && (
          <div className="mx-1">
            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={showWeekend}
                class="sr-only peer"
                onChange={(e) => {
                  setShowWeekend(e.target.checked);
                }}
              />
              <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Week-ends
              </span>
            </label>
          </div>
        )}
        <div className="mx-1">
          <label class="inline-flex items-center cursor-pointer mx-1">
            <input
              type="checkbox"
              checked={companionsView}
              class="sr-only peer"
              onChange={(e) => {
                setCompanionsView(e.target.checked);
              }}
            />
            <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Afficher compagnons
            </span>
          </label>
          <label class="inline-flex items-center cursor-pointer mx-1">
            <input
              type="checkbox"
              checked={subcontractorsView}
              class="sr-only peer"
              onChange={(e) => {
                setSubcontractorsView(e.target.checked);
              }}
            />
            <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Afficher sous-traitants
            </span>
          </label>
        </div>
      </div>
    );
  };

  /* Render using table */
  return (
    <>
      {/* L'erreur si présente */}
      <h1 className="text-red-600 w-full text-center">
        {apiGetErrorChantiers && apiGetErrorChantiers.message}
      </h1>
      <div className={`w-full flex mx-auto text-center my-2`}>
        <div className="basis-1/3 flex">{navDate()}</div>
        <div className="basis-1/3">
          <button
            onClick={() => setViewPlanning("day")}
            className={`${
              viewPlanning == "day" && "border-2 border-blue-500"
            } relative inline-flex items-center justify-center gap-2 text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-slate-200 hover:bg-[#F5F5F5] h-9 rounded-md px-3 group`}
          >
            Jour
          </button>{" "}
          <button
            onClick={() => setViewPlanning("week")}
            className={`${
              viewPlanning == "week" && "border-2 border-blue-500"
            } relative inline-flex items-center justify-center gap-2 text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-slate-200 hover:bg-[#F5F5F5] h-9 rounded-md px-3 group`}
          >
            Semaine
          </button>{" "}
          <button
            onClick={() => setViewPlanning("month")}
            className={`${
              viewPlanning == "month" && "border-2 border-blue-500"
            } relative inline-flex items-center justify-center gap-2 text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-slate-200 hover:bg-[#F5F5F5] h-9 rounded-md px-3 group`}
          >
            Mois
          </button>{" "}
          <button
            onClick={() => setViewPlanning("quarter")}
            className={`${
              viewPlanning == "quarter" && "border-2 border-blue-500"
            } relative inline-flex items-center justify-center gap-2 text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-slate-200 hover:bg-[#F5F5F5] h-9 rounded-md px-3 group`}
          >
            Trimestre
          </button>
        </div>
        <div className="basis-1/3 flex">
          <button onClick={() => setShowChantiers(!showChantiers)}>
            {!showChantiers ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mx-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            )}
          </button>
          <button onClick={() => setDeletePlanning(!deletePlanning)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill={deletePlanning ? "orange" : "none"}
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="mx-auto w-full flex">
        <div
          className={`${
            !showChantiers ? "basis-full" : "basis-11/12"
          } bg-slate-50`}
        >
          {viewPlanning == "day" && (
            <DayView
              setStateData={setStateData}
              planifications={apiGetData && apiGetData}
              persons={apiGetDataUsers && filterUsers(apiGetDataUsers)}
              currentDate={dateView}
              selectedChantier={selectedChantier}
              editPlanning={editPlanning}
              deletePlanning={deletePlanning}
              setDeleteId={setDeleteId}
              setUpdateId={setUpdateId}
              updateId={updateId}
              hoverId={hoverId}
              setHoverId={setHoverId}
              setResponse={setResponse}
            />
          )}
          {viewPlanning == "week" && (
            <WeekView
              setStateData={setStateData}
              planifications={apiGetData && apiGetData}
              persons={apiGetDataUsers && filterUsers(apiGetDataUsers)}
              currentDate={dateView}
              selectedChantier={selectedChantier}
              editPlanning={editPlanning}
              deletePlanning={deletePlanning}
              setDeleteId={setDeleteId}
              setUpdateId={setUpdateId}
              updateId={updateId}
              showWeekend={showWeekend}
              hoverId={hoverId}
              setHoverId={setHoverId}
              setResponse={setResponse}
            />
          )}
          {viewPlanning == "month" && (
            <MonthView
              setStateData={setStateData}
              planifications={apiGetData && apiGetData}
              persons={apiGetDataUsers && filterUsers(apiGetDataUsers)}
              currentDate={dateView}
              selectedChantier={selectedChantier}
              editPlanning={editPlanning}
              deletePlanning={deletePlanning}
              setDeleteId={setDeleteId}
              setUpdateId={setUpdateId}
              updateId={updateId}
              showWeekend={showWeekend}
              hoverId={hoverId}
              setHoverId={setHoverId}
              setResponse={setResponse}
            />
          )}
          {viewPlanning == "quarter" && (
            <QuarterView
              setStateData={setStateData}
              planifications={apiGetData && apiGetData}
              persons={apiGetDataUsers && filterUsers(apiGetDataUsers)}
              currentDate={dateView}
              selectedChantier={selectedChantier}
              editPlanning={editPlanning}
              deletePlanning={deletePlanning}
              setDeleteId={setDeleteId}
              setUpdateId={setUpdateId}
              updateId={updateId}
              showWeekend={showWeekend}
              hoverId={hoverId}
              setHoverId={setHoverId}
              setResponse={setResponse}
            />
          )}
        </div>
        {showChantiers && (
          <div className="basis-1/12 px-2">
            <Chantiers
              setSelectedChantier={setSelectedChantier}
              selectedChantier={selectedChantier}
              chantiers={apiGetDataChantiers && apiGetDataChantiers}
            />
          </div>
        )}
      </div>
      {/* {hoverId && (
        <div className="w-full px-2">
          <div className="flex gap-2">
            <InfosChantier hoverId={hoverId} setResponse={setResponse} />
            <InfosPlanification
              planificationId={updateId}
              setResponse={setResponse}
            />
            <div className="h-fit my-2 mx-auto basis-1/3 border-2 rounded-md p-2">
              <h1 className="text-xl">Fonctionnalités à développer</h1>
              <span>Météo, Statistiques, Rentabilité, etc.</span>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
