import { Disclosure, Menu, Transition } from "@headlessui/react"
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { AuthService } from "~/services/authService"

const USER = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
      'https://media.licdn.com/dms/image/C4E03AQHGJUlDHqz3Ng/profile-displayphoto-shrink_400_400/0/1587746475384?e=2147483647&v=beta&t=PbKZhGXMeNTFQCnt46Qji_Agn-VTTvOGBcA6V0qnKUs',
}

function classNames(...classes) {
    return  classes.filter(Boolean).join(' ')
}

export default function NavBar() {

    const [navigation,  setNavigation] = useState([])
    const [userNavigation, setUserNavigation] = useState([])

    const authService = new AuthService()
    const {user} = authService.useUserContext()

    const location = useLocation()

    
    useEffect(() => {
        if(user.isLoggedIn) {

            setNavigation(
                [
                    { name: 'Accueil', href: '/' },
                    { name: 'Clients', href: '/clients' },
                    { name: 'Chantiers', href: '/chantiers' },
                    { name: 'Planning', href: '/planning' },
                    { name: 'Assistant', href: '/assistant' },
                    { name: 'Administration', href: '/administration' },
                ]
            )

            setUserNavigation([
                { name: 'Préférences', href: '/profile' },
                { name: 'Se déconnecter', href: '/logout' },
            ])

        } else {

            setNavigation(
                [
                    { name: 'Connexion', href: '/login' },
                ]
            )
            setUserNavigation([])
        }


    }, [user.isLoggedIn])

    return <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
            <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <img
                                    className="h-12 w-12 rounded-full"
                                    src={`${window.location.origin}/amac.png`}
                                    alt="Amhac"
                                />
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className={classNames(
                                            ((location.pathname.startsWith(item.href) && item.href != "/") || (location.pathname =="/" && item.href == "/"))
                                                ? 'bg-gray-900 text-white'
                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'rounded-md px-3 py-2 text-sm font-medium'
                                            )}
                                            aria-current={location.pathname.startsWith(item.href) ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                        { user.isLoggedIn && <>
                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">
                                <button
                                    type="button"
                                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                >
                                    <span className="sr-only">Voir notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span className="sr-only">Menu utilisateurs</span>
                                        <img className="h-8 w-8 rounded-full" src={USER.imageUrl} alt="" />
                                    </Menu.Button>
                                    </div>
                                    <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                    >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                        <Menu.Item key={item.name}>
                                            {({ active }) => (
                                            <Link
                                                to={item.href}
                                                className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700'
                                                )}
                                            >
                                                {item.name}
                                            </Link>
                                            )}
                                        </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                    </Transition>
                                </Menu>
                                <span className="text-slate-300 text-sm mx-2">Version Beta</span>
                            </div> 
                        </div>
                        </>}
                        <div className="-mr-2 flex md:hidden">
                            {/* Mobile menu button */}
                            <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                ) : (
                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                )}
                            </Disclosure.Button>
                        </div>
                    </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {navigation.map((item) => (
                            <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className={classNames(
                                ((location.pathname.startsWith(item.href) && item.href != "/") || (location.pathname =="/" && item.href == "/"))
                                ? 'bg-gray-900 text-white' 
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'block rounded-md px-3 py-2 text-base font-medium'
                            )}
                            aria-current={location.pathname.startsWith(item.href) ? 'page' : undefined}
                            >
                            {item.name}
                            </Disclosure.Button>
                        ))}
                    </div>
                    { user.isLoggedIn && <>
                    <div className="border-t border-gray-700 pb-3 pt-4">
                        <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                                <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium leading-none text-white">{user.name}</div>
                                <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                            </div>
                            <button
                            type="button"
                            className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                            >
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-3 space-y-1 px-2">
                            {userNavigation.map((item) => (
                            <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                            >
                                {item.name}
                            </Disclosure.Button>
                            ))}
                        </div>
                    </div>
                    </>}
                </Disclosure.Panel>
            </>
        )}
        </Disclosure>

}