import { useModals, useNavigate } from "~/router"
import { useLocation } from "react-router-dom"
import { ClientsService } from "~/services/clientsService"
import { useEffect } from "react"
import { useState } from "react"
import { HashLoader } from "react-spinners"

export default function DeleteConfirm() {

    /* Un etat permettant d'afficher un message d'erreur */
    const [errorMessage, setErrorMessage] = useState(null)

    /* Permet de récupérer le state passé à la modal (id de la personne, nom et prénom) */
    const location = useLocation()
    const data = location.state.data || {nom: 'undefined', prenom:'undefined', id:null}
    
    /* Permet la redirection apres la suppression */
    const navigate = useNavigate()
    
    /* Hook permettant de gérer la modal (fermeture) */
    const modals = useModals()
    const handleClose = () => modals.close()

    /* Le service API et le hook permettant de supprimer une personne */
    const clientsService = new ClientsService()
    const {data: apiDeleteData, error: apiDeleteError, isLoading: apiDeleteIsLoading, callToAction: apiDeleteHandler} = clientsService.useDelete(data.id)

    /* En cas d'erreur, on la "déchiffre" et on l'affiche */
    useEffect(() => {
        if (!apiDeleteError) setErrorMessage(null)
        else if (apiDeleteError.response && apiDeleteError.response.status === 403) setErrorMessage("Vous n'êtes pas autorisé à supprimer cette ressource")
        else if (apiDeleteError.response && apiDeleteError.response.status === 401) navigate('/logout')
        else if (apiDeleteError.message) setErrorMessage('Erreur du serveur : ' + apiDeleteError.message)
        else {console.log(apiDeleteError); setErrorMessage('Erreur inconnue')}
    }, [apiDeleteError])

    /* Lorsque le hook de delete met à jour le data indiquant la fin de la suppression */
    useEffect(() => {
        if(apiDeleteData === true) {
            navigate('/personnes')
        }
    }, [apiDeleteData])

    return (
        <div className="fixed inset-0 bg-black/25 grid place-content-center">
            {/* La div gris en fond */}

            {/* La div en fond qui permet de fermer la modal au clique à l'extérieur */}
            <div className="absolute inset-0 -z-10" onClick={handleClose} />

            {/* La div principale de la modale */}
            <div className="bg-white p-[40px] w-[600px] border rounded-xl">
                
                {/* Le texte */}
                <h2 className="text-red-600 text-center text-2xl font-bold leading-9 tracking-tight text-gray-9000 mb-4">Confirmation</h2>
                <p className="text-center text-xl">Vous êtes sur le point de supprimer <span className="uppercase">{data.nom}</span> <span className="capitalize">{data.prenom}</span></p>
                
                {/* Les boutons */}
                <div className='flex justify-around mt-4'>
                    <button 
                        onClick={handleClose}
                        className="p-3 border rounded-md border-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-500">
                        Annuler
                    </button>
                    <button 
                        onClick={apiDeleteHandler}
                        disabled={apiDeleteIsLoading}
                        className={`p-3 border rounded-md inline-flex items-center ${apiDeleteIsLoading ? 'bg-red-400 border-red-400': 'border-red-600 hover:bg-red-600 hover:text-white transition-colors duration-500'}`}>
                        {apiDeleteIsLoading && (
                            <HashLoader color="white" size={10} />
                        )}
                        {apiDeleteIsLoading ? "Suppression en cours" : "Confirmer"}       
                    </button>
                </div>

                {/* Le message d'erreur */}
                {errorMessage && ( <p className="text-center p-3 text-red-600">{errorMessage}</p> )}
            </div>
        </div>
    )
}