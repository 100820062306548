import { useState } from "react";
import useSWR from "swr";
import axios from "axios";

export const communesAPI = axios.create({
  baseURL: "https://geo.api.gouv.fr",
  timeout: 30000,
});

export const autoAPI = axios.create({
  baseURL: "https://api-adresse.data.gouv.fr",
  timeout: 30000,
});

export class CommunesService {
  useGetCommunesAuto(q, postcode) {
    const fetcher = (url) => autoAPI.get(url).then((res) => res.data);

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      `/search/?q=${q}${postcode ? "&postcode=" + postcode : ""}&limit=50`,
      fetcher
    );

    return {
      data: data,
      error,
      isLoading: isLoading || isValidating,
      callToAction: mutate,
    };
  }

  useGetCommunesByName(nom) {
    const fetcher = (url) => communesAPI.get(url).then((res) => res.data);

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      `/communes?nom=${nom}&fields=code,nom,codesPostaux`,
      fetcher
    );

    return {
      data: data,
      error,
      isLoading: isLoading || isValidating,
      callToAction: mutate,
    };
  }

  useGetCommunesByCP(codePostal) {
    const fetcher = (url) => communesAPI.get(url).then((res) => res.data);

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      `/communes?codePostal=${codePostal}`,
      fetcher
    );

    return {
      data: data,
      error,
      isLoading: isLoading || isValidating,
      callToAction: mutate,
    };
  }
}
