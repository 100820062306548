import { Link } from "react-router-dom";

export default function Error404() {
    return (
        <div className="sm:py-8 h-screen">
            <div className="sm:mx-auto sm:w-full sm:max-w-lg sm:rounded-xl sm:border sm:border-gray-500 bg-white shadow-xl p-2 flex flex-col">
                <div>
                    <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Simple React App" />
                    <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-9000">404</h2>
                </div>
                <div className="mt-10 text-center">
                    <p className="">La page que vous demandez ne peut être trouvée</p>
                    <Link to="/" className="mt-10 block rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-indigo-800 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Retour à l'acceuil</Link>
                </div>
            </div>
        </div>
    )
}