export const types_clients = [
  "Particulier",
  "TPE/PME",
  "Administration",
  "Industrie",
  "Bailleur Social",
  "Assurance",
  "Association",
];

export const phases_chantiers = [
  { id: 0, name: "A contacter", delay: 0 },
  { id: 1, name: "A chiffrer", delay: 0 },
  { id: 2, name: "En attente de prix", delay: 0 },
  { id: 3, name: "Chiffrage transmis", delay: 0 },
  { id: 4, name: "A commander", delay: 0 },
  { id: 5, name: "A planifier", delay: 0 },
  { id: 6, name: "En cours", delay: 0 },
  { id: 7, name: "A facturer", delay: 0 },
  { id: 8, name: "Terminé", delay: 0 },
  { id: 9, name: "SAV", delay: 0 },
];
