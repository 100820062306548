import { Link, useOutletContext } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "~/router";
import { ClientsService } from "~/services/clientsService";
import { useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";
import { BeatLoader } from "react-spinners";
import { types_clients } from "~/utils/refs.js";
import InputSearchBAN from "~/components/InputSearchBAN";

export default function EditChantier() {
  /* useParams() est un hook permettant de récupérer les variables GET */
  const { id } = useParams();

  /* hook utile a la redirection, après l'édition ou pour le retour à la liste */
  const navigate = useNavigate();

  /* Le state stockant les données de la personnes à modifier et utilisé dans le formulaire */
  const [stateData, setStateData] = useState({
    name: "",
    alt_name: "",
    address: "",
    city: "",
    postal_code: "",
    phone1: "",
    phone2: "",
    type: "",
    active: true,
  });

  /* Création du service API pour les personnes et utilisation de deux hook :
       - Le hook de récupération des données du client à modifier
       - Le hook de modification des données */
  const clientsService = new ClientsService();
  const {
    data: apiGetData,
    error: apiGetError,
    isLoading: apiGetIsLoading,
  } = clientsService.useGetOne(id);

  const {
    data: apiUpdateData,
    error: apiUpdateError,
    isLoading: apiUpdateIsLoading,
    callToAction: apiUpdate,
  } = clientsService.useUpdate(id, stateData);

  /* Permet le changement du titre de la page */
  const [_, setPageTitle] = useOutletContext();

  /* Au chargement, les données de la personne à modifier sont récupérées, stockées dans le résultat du hook
       et le useEffect va les mettre dans la variable d'état et plus de modifier le titre de la page */
  useEffect(() => {
    if (apiGetData && apiGetData.id) {
      setPageTitle(`Edition du client ${apiGetData.name}`);
      setStateData({
        name: apiGetData.name,
        alt_name: apiGetData.alt_name,
        address: apiGetData.address,
        city: apiGetData.city,
        postal_code: apiGetData.postal_code,
        phone1: apiGetData.phone1,
        phone2: apiGetData.phone2,
        type: apiGetData.type,
        active: apiGetData.active,
      });
    }
  }, [apiGetData]);

  /* Si 401 (not authenticated) à la récupération de l'utilisateur, on redirige vers le logout */
  if (apiGetError) {
    if (apiGetError.response && apiGetError.response.status === 401)
      return <Navigate to="/logout" />;
  }

  return (
    <>
      {/* Le header contenant le bouton de retour, le loader, les erreurs si il y en a, et le bouton de sauvegarde */}
      <div className="w-full md:w-2/3 flex justify-between text-center mx-auto">
        <Link
          className="rounded-md border border-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-500 p-4"
          to={`/clients`}
          title="Retour a la liste"
        >
          <ArrowLeftIcon className="h-4 inline" />
        </Link>

        <BeatLoader size={10} loading={apiGetIsLoading} className="ml-8" />

        {apiGetError && (
          <span className="text-red-600">{apiGetError.message}</span>
        )}
        {apiUpdateError && (
          <span className="text-red-600">{apiUpdateError.message}</span>
        )}

        <button
          className={`rounded-md border border-green-600 ${
            apiUpdateIsLoading
              ? "bg-green-200"
              : "hover:bg-green-600 hover:text-white"
          } transition-colors duration-500 p-4`}
          onClick={apiUpdate}
          disabled={apiUpdateIsLoading}
        >
          <ArrowDownOnSquareIcon className="h-4 inline" />
        </button>
      </div>

      {/* Le formulaire permettant de modifier */}
      <table className="w-full md:w-2/3 text-lg mt-4 table-auto mx-auto">
        <tbody>
          <tr>
            <td className="font-bold py-4">Type</td>
            <td>
              <select
                data-te-select-initid="type"
                name="type"
                type="text"
                className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                onChange={(e) =>
                  setStateData({
                    ...stateData,
                    type: e.target.value != "" ? e.target.value : null,
                  })
                }
              >
                <option value=""></option>
                {types_clients.map((type) => {
                  return (
                    <option
                      selected={stateData.type == type ? true : false}
                      value={type}
                    >
                      {type}
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Nom du client</td>
            <td>
              <input
                id="name"
                name="name"
                className="rounded-lg w-full"
                type="text"
                value={stateData.name}
                onChange={(e) =>
                  setStateData({ ...stateData, name: e.target.value })
                }
              />
            </td>
          </tr>
          <InputSearchBAN data={stateData} maj={setStateData} />
          <tr>
            <td className="font-bold py-4">Téléhpone 1</td>
            <td>
              <input
                id="phone1"
                name="phone1"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone1}
                onChange={(e) =>
                  setStateData({ ...stateData, phone1: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold py-4">Téléphone 2</td>
            <td>
              <input
                id="phone2"
                name="phone2"
                className="rounded-lg w-full"
                type="text"
                value={stateData.phone2}
                onChange={(e) =>
                  setStateData({ ...stateData, phone2: e.target.value })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
