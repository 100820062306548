import React, { useEffect, useState } from "react";
import { CommunesService } from "~/services/communesService";

const InputSearchBAN = (props) => {
  const data = props.data;
  const maj = props.maj;
  const [q, setQ] = useState();

  /* le service d'API et le hook permettant de récupérer toutes les communes */
  const communesService = new CommunesService();
  const {
    data: apiGetDataCommunesAuto,
    error: apiGetErrorCommunesAuto,
    isLoading: apiGetIsLoadingCommunesAuto,
    callToAction: apiGetHandlerCommunesAuto,
  } = communesService.useGetCommunesAuto(q);

  const searchQ = (name, value) => {
    maj({ ...data, [name]: value });
    if (String(value).length >= 3) {
      setQ(encodeURI(value));
    } else {
      setQ();
    }
  };

  const listCP = () => {
    return apiGetDataCommunesAuto.features.map((item) => {
      if (item.properties.type == "municipality")
        return (
          <span
            className="flex hover:bg-slate-200 cursor-pointer"
            onClick={() => {
              maj({
                ...data,
                city: item.properties.city,
                postal_code: item.properties.postcode,
              });
              setQ();
            }}
          >
            {item.properties.postcode} - {item.properties.city}
          </span>
        );
    });
  };

  const listCities = () => {
    return apiGetDataCommunesAuto.features.map((item) => {
      if (item.properties.type == "municipality")
        return (
          <span
            className="flex hover:bg-slate-200 cursor-pointer"
            onClick={() => {
              maj({
                ...data,
                city: item.properties.city,
                postal_code: item.properties.postcode,
              });
              setQ();
            }}
          >
            {item.properties.city} ({item.properties.postcode})
          </span>
        );
    });
  };

  const listAddresses = () => {
    return apiGetDataCommunesAuto.features.map((item) => {
      if (
        item.properties.type == "housenumber" ||
        item.properties.type == "street"
      )
        return (
          <span
            className="flex hover:bg-slate-200 cursor-pointer"
            onClick={() => {
              maj({
                ...data,
                address: item.properties.name,
                city: item.properties.city,
                postal_code: item.properties.postcode,
              });
              setQ();
            }}
          >
            {item.properties.label}
          </span>
        );
    });
  };

  return (
    <>
      <tr>
        <td className="font-bold py-4">Adresse</td>
        <td>
          <input
            id="address"
            name="address"
            className="rounded-lg w-full"
            type="text"
            value={data.address}
            onChange={(e) => searchQ(e.target.name, e.target.value)}
          />
          {apiGetDataCommunesAuto && data.address && (
            <div className="m-2 flex-col text-sm w-full max-h-24 overflow-y-scroll">
              {listAddresses()}
            </div>
          )}
        </td>
      </tr>
      <tr>
        <td className="font-bold py-4">Ville</td>
        <td>
          <input
            id="city"
            name="city"
            className="rounded-lg w-full"
            type="text"
            value={data.city}
            onChange={(e) => searchQ(e.target.name, e.target.value)}
          />
          {apiGetDataCommunesAuto && data.city && (
            <div className="m-2 flex-col text-sm w-full max-h-24 overflow-y-scroll">
              {listCities()}
            </div>
          )}
        </td>
      </tr>
      <tr>
        <td className="font-bold py-4">Code Postal</td>
        <td>
          <input
            id="postal_code"
            name="postal_code"
            className="rounded-lg w-full"
            type="text"
            value={data.postal_code}
            onChange={(e) => searchQ(e.target.name, e.target.value)}
          />
          {apiGetDataCommunesAuto && data.postal_code && (
            <div className="m-2 flex-col text-sm w-full max-h-24 overflow-y-scroll">
              {listCP()}
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default InputSearchBAN;
