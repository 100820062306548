import { Outlet } from "react-router-dom";

export default function Layout() {
    return (
        <>
            <main className="bg-gray-100">
                <Outlet />
            </main>
        </>
    )
}