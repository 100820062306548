import { createContext, useContext, useState } from "react"
import { isLoggedIn } from "../utils/auth"

const defaultUser = {
    isLoggedIn: isLoggedIn(),
    firstName: "",
    lastName: ""
}

const UserContext = createContext(defaultUser)

export const UserContextProvider = ({children}) => {
    const [user, setUser] = useState(defaultUser)

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => {
    const { user, setUser } = useContext(UserContext)
    return {user, setUser}
}