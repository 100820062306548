import { ClientsService } from "~/services/clientsService";
import { Link, Navigate } from "~/router";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { ArrowPathIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { BeatLoader } from "react-spinners";

export default function Index() {
  /* Le state permettant de gérer la recherche */
  const [search, setSearch] = useState("");

  /* le service d'API et le hook permettant de récupérer toutes les clients */
  const clientsService = new ClientsService();
  const {
    data: apiGetData,
    error: apiGetError,
    isLoading: apiGetIsLoading,
    callToAction: apiGetHandler,
  } = clientsService.useGetAll();

  /* Le hook permettant de changer le titre de la page et le useEffect qui le fait */
  const [_, setPageTitle] = useOutletContext();

  useEffect(() => {
    setPageTitle("Liste des clients");
  }, []);

  /* Si erreur 401 not authenticated, on redirige vers la page de logout */
  if (apiGetError) {
    if (apiGetError.response && apiGetError.response.status === 401)
      return <Navigate to="/logout" />;
  }

  /* Render using table */
  return (
    <>
      {/* l'entête contenant le champs de recherche, le bouton de refresh, le loader et le bouton d'ajout */}
      <div className="flex justify-between w-full mb-4">
        <div className="flex flex-row items-center">
          <input
            className="rounded-md border-sky-600"
            type="text"
            placeholder="Rechercher"
            onChange={(e) => setSearch(e.target.value)}
          />

          {/* <button
            name="refresh"
            disabled={apiGetIsLoading}
            onClick={apiGetHandler}
            className="ml-8 px-4 border rounded-md border-sky-600 p-2 hover:bg-sky-600 hover:text-white transitions-colors duration-500"
          >
            <ArrowPathIcon className="h-4 inline" />
          </button> */}

          <BeatLoader size={10} loading={apiGetIsLoading} className="ml-8" />
        </div>

        <Link
          to="/clients/add"
          className="border rounded-md border-sky-600 p-2 hover:bg-sky-600 hover:text-white transitions-colors duration-500"
        >
          Ajouter
        </Link>
      </div>

      {/* L'erreur si présente */}
      <h1 className="text-red-600 w-full text-center">
        {apiGetError && apiGetError.message}
      </h1>

      {/* La table contenant les données */}
      <table
        className={`table-auto w-full border border-collapse rounded-xl ${
          apiGetIsLoading && "blur-sm"
        }`}
      >
        <thead>
          <tr>
            <th className="border border-collapse py-1">Nom</th>
            <th className="border border-collapse">Adresse</th>
            <th className="border border-collapse">Ville</th>
            <th className="border border-collapse">Code Postal</th>
            <th className="border border-collapse">Téléphone(s)</th>
            <th className="border border-collapse">Type</th>
            <th className="border border-collapse"></th>
          </tr>
        </thead>
        <tbody>
          {apiGetData &&
            apiGetData.map((client, i) => {
              const found =
                client.name && client.name.toLowerCase().includes(search.toLowerCase()) ||
                client.address && client.address.toLowerCase().includes(search.toLowerCase()) ||
                client.city && client.city.toLowerCase().includes(search.toLowerCase());
              if (found)
                return (
                  <tr
                    key={client.id}
                    className={`${
                      i % 2 === 0 && "bg-sky-100"
                    } hover:bg-sky-200 ${
                      client.active === false && "font-italic text-gray-CCC"
                    }`}
                  >
                    <td className="border border-collapse text-center uppercase py-1">
                      {client.name}
                    </td>
                    <td className="border border-collapse text-center lowercase">
                      {client.address}
                    </td>
                    <td className="border border-collapse text-center">
                      {client.city}
                    </td>
                    <td className="border border-collapse text-center">
                      {client.postal_code}
                    </td>
                    <td className="border border-collapse text-center">
                      {client.phone1}
                      {client.phone2 != null && " / " + client.phone2}
                    </td>
                    <td className="border border-collapse text-center">
                      {client.type}
                    </td>
                    <td className="border border-collapse text-center">
                      <Link className="" to={`/clients/edit/${client.id}`}>
                        <ArrowRightIcon className="inline w-4" />
                      </Link>
                    </td>
                  </tr>
                );
            })}
        </tbody>
      </table>
    </>
  );
}
