import { Navigate } from "react-router-dom"
import { isLoggedIn } from "~/utils/auth"
import { AuthService } from "~/services/authService"

export const RouteGuard = ({children}) => {

    const authService = new AuthService()
    const {user} = authService.useUserContext()

    if (!user.isLoggedIn) return <Navigate to='/login' />

    return children
}