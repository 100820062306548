import { useState } from "react";
import { publicAPI } from "./base";
import { saveTokens, clearTokens } from "../utils/auth";
import { useUserContext } from "../components/UserContext";

export class AuthService {
  useLogin(username, password) {
    const { user, setUser } = useUserContext();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function login() {
      setIsLoading(true);
      setError(null);

      publicAPI
        .post(
          "/oauth2/login",
          {
            username: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.access_token && response.data.refresh_token) {
            saveTokens(response.data.access_token, response.data.refresh_token);
            setUser((user) => {
              user.isLoggedIn = true;
              return user;
            });
          }
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return {
      data: user,
      error,
      isLoading,
      callToAction: login,
    };
  }

  useLogout() {
    const { user, setUser } = useUserContext();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function logout() {
      try {
        setIsLoading(true);
        clearTokens();

        setTimeout(() => {
          setUser((user) => {
            user.isLoggedIn = false;
            return user;
          });
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        console.log(error);
        setError("Erreur inconnue");
      }
    }

    return {
      data: user,
      error,
      isLoading,
      callToAction: logout,
    };
  }

  useUserContext() {
    const { user, setUser } = useUserContext();
    return {
      user,
      setUser,
    };
  }
}
