import { useModals, useNavigate } from "~/router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { PlanificationsService } from "~/services/planificationsService";
import moment from "moment";

export default function DeleteConfirm() {
	/* Un etat permettant d'afficher un message d'erreur */
	const [errorMessage, setErrorMessage] = useState(null);

	const [stateData, setStateData] = useState();

	/* Permet de récupérer le state passé à la modal  */
	const location = useLocation();
	const data = location.state.data;
	const id = location.state.id;
	const title = location.state.title;

	/* Permet la redirection apres la suppression */
	const navigate = useNavigate();

	/* Hook permettant de gérer la modal (fermeture) */
	const modals = useModals();
	const handleClose = () => modals.close();

	/* le service d'API et le hook permettant de récupérer toutes les planifications */
	const planificationsService = new PlanificationsService();
	const {
		data: apiGetDataPlanifications,
		error: apiGetErrorPlanifications,
		isLoading: apiGetIsLoadingPlanifications,
		callToAction: apiGetHandlerPlanifications,
	} = planificationsService.useGetAll(id);

	return (
		<div className="fixed inset-0 bg-black/25 grid place-content-center">
			{/* La div gris en fond */}

			{/* La div en fond qui permet de fermer la modal au clique à l'extérieur */}
			<div className="absolute inset-0 -z-10" onClick={handleClose} />

			{/* La div principale de la modale */}
			<div className="bg-white p-[40px] min-w-[800px] border rounded-xl overflow-auto">
				{/* Le texte */}
				<h2 className="text-red-600 text-center text-xl font-bold leading-9 tracking-tight text-gray-9000 mb-4">
					{title}
					{data.title}
				</h2>
				<table className="min-w-full table-auto border-collapse bg-white shadow-md">
					<thead className="bg-yellow-600 text-white">
						<tr>
							<th className="p-3 text-center">Date d'intervention</th>
							<th className="p-3 text-center">Intervenant</th>
						</tr>
					</thead>
					<tbody>
						{apiGetDataPlanifications &&
							[...apiGetDataPlanifications]
								.sort((a, b) => new Date(a.date) - new Date(b.date))
								.map((planification, i) => (
									<tr key={i} className="hover:bg-gray-100 odd:bg-gray-50">
										<td className="text-center text-lg text-gray-800 border-b">
											{moment(planification.date).format("DD/MM/YYYY")}
										</td>
										<td className="text-center text-lg text-gray-800 border-b">
											{planification.assigned_user.first_name}{" "}
											{planification.assigned_user.last_name}
										</td>
									</tr>
								))}
					</tbody>
				</table>

				{/* Les boutons */}
				<div className="flex justify-around mt-4">
					<button
						onClick={handleClose}
						className="p-3 border rounded-md border-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-500"
					>
						Fermer
					</button>
				</div>

				{/* Le message d'erreur */}
				{errorMessage && (
					<p className="text-center p-3 text-red-600">{errorMessage}</p>
				)}
			</div>
		</div>
	);
}
