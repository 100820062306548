import React, { useEffect, useState } from "react";
import { ChantiersService } from "~/services/chantiersService";
import Rentabilite from "~/components/planning/infosStats/Rentabilite";

const InfosStats = (props) => {
  const persons = props.persons ? props.persons : [];
  const hoverId = props.hoverId ? props.hoverId : 0;
  const planifications = props.planifications ? props.planifications : [];

  // Création du service API et appel du hook utilisé pour la création de chantiers
  const chantiersService = new ChantiersService();
  const {
    data: apiGetDataChantier,
    error: apiGetErrorChantier,
    isLoading: apiGetIsLoadingChantier,
    callToAction: apiGetHandlerChantier,
  } = chantiersService.useGetOne(hoverId);

  return (
    <div className="h-fit my-2 mx-auto basis-1/3 border-2 rounded-md p-2 shadow-md">
      <h1 className="text-xl flex m-2">
        Statistiques et fonctionalités
      </h1>
      <Rentabilite persons={persons} hoverId={hoverId} chantier={apiGetDataChantier} planifications={planifications}/>
    </div>
  );
};

export default InfosStats;
