// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/about`
  | `/administration`
  | `/assistant`
  | `/chantiers`
  | `/chantiers/add`
  | `/chantiers/edit/:id`
  | `/clients`
  | `/clients/add`
  | `/clients/edit/:id`
  | `/login`
  | `/logout`
  | `/planning`

export type Params = {
  '/chantiers/edit/:id': { id: string }
  '/clients/edit/:id': { id: string }
}

export type ModalPath = `/administration/delete` | `/chantiers/delete` | `/chantiers/edit/informations` | `/clients/delete`

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
